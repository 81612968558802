import React from "react";

import { AccordionRichText } from "~components/AccordionRichText";
import { Box } from "~components/Box";
import { Grid } from "~components/Grid";
import { PhoneMockup } from "~components/PhoneMockup";
import { SectionHeader } from "~components/SectionHeader";

import { AnimationSlider } from "~animations/animation_primitives/AnimationSlider";
import { MOCK_UI_SCREENS } from "~animations/mock_ui/MockUiMap";

import {
  SequencedAnimationsContext,
  SequencedAnimationsContextProvider,
} from "~context/SequencedAnimationsContext";

import type { AccordionAnimationItemProps } from "~animations/animation_primitives/AccordionAnimationItem";
import type { SectionHeaderProps } from "~components/SectionHeader";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface AccordionHowItWorksProps extends StoryblokBlok {
  isScrollable?: boolean;
  items: Array<Omit<AccordionAnimationItemProps, "index"> & StoryblokBlok>;
  sectionHeader?: Array<StoryblokBlok & SectionHeaderProps>;
}

const GRID_TEMPLATE_COLUMNS = {
  mobile: 1,
  desktop: 2,
} as const;

export function AccordionHowItWorks({
  items,
  sectionHeader: sectionHeaderBlokArray,
  ...rest
}: AccordionHowItWorksProps) {
  const [sectionHeader] = sectionHeaderBlokArray || [];

  return (
    <SequencedAnimationsContextProvider
      length={items?.length}
      orientation="horizontal"
    >
      <SequencedAnimationsContext.Consumer>
        {({ current, previous, handleUserSetState }) => {
          return (
            <Grid
              gridTemplateColumns={GRID_TEMPLATE_COLUMNS}
              alignItems="start"
              {...rest}
            >
              {/**
               * ----------------------------------------------
               * Main content block
               * includes
               * - section header
               * - accordion controller for animations carousel
               * ----------------------------------------------
               */}
              <Box>
                {sectionHeader && (
                  <SectionHeader
                    marginBottom="sectionRowGap"
                    {...sectionHeader}
                  />
                )}
                <AccordionRichText
                  value={current?.toString()}
                  onValueChange={handleUserSetState}
                  accordionItems={items}
                  collapsible={false}
                  shouldShowNumbers
                />
              </Box>

              {/**
               * ----------------------------------------------
               * Video slider
               * - renders a animation corresponding to the active accordion item
               * - transitions animations using Carousel
               * ----------------------------------------------
               */}

              {Array.isArray(items) && (
                <PhoneMockup
                  marginLeft="auto"
                  position="relative"
                  display={{ mobile: "none", desktop: "block" }}
                  aspectRatio="mobile"
                  justifyContent="center"
                  width="100%"
                  {...rest}
                >
                  <AnimationSlider
                    animationComponentMap={MOCK_UI_SCREENS}
                    items={items}
                    current={current}
                    previous={previous}
                  />
                </PhoneMockup>
              )}
            </Grid>
          );
        }}
      </SequencedAnimationsContext.Consumer>
    </SequencedAnimationsContextProvider>
  );
}
