import { useCallback, useEffect, useState } from "react";

import { getMediaQuery } from "./helper_functions/get_media_query";

import type { ScreenSize } from "./helper_functions/get_media_query";

/** Determine if device matches specified min and/or max breakpoint. */
export function useMatchMedia({
  min,
  max,
}: {
  /** The min breakpoint name. */
  min?: ScreenSize;
  /** The max breakpoint name. */
  max?: ScreenSize;
}) {
  const query = getMediaQuery({ min, max });

  // Defaults to false until matchMedia has changed
  const [doesMatch, setDoesMatch] = useState(false);

  const handleChange = useCallback(({ matches }: { matches: boolean }) => {
    setDoesMatch(matches);
  }, []);

  // Create matchMedia singleton if doesn't exist yet

  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      typeof window.matchMedia !== "undefined"
    ) {
      const matcher = window.matchMedia(query);

      if (matcher && matcher.addEventListener) {
        matcher.addEventListener("change", handleChange);
        setDoesMatch(matcher.matches);
      }

      return () => {
        if (
          typeof window !== "undefined" &&
          typeof window.matchMedia !== "undefined"
        ) {
          matcher.removeEventListener("change", handleChange);
        }
      };
    }

    return () => {};
  }, [handleChange, query]);

  return doesMatch;
}
