import React from "react";

import { Box } from "~components/Box";
import { FormHubspot } from "~components/FormHubspot";
import { SectionHeader } from "~components/SectionHeader";

import type { BoxProps } from "~components/Box";
import type { GetSprinklesArgs } from "~styles/getSprinkles.css";

export interface FooterNewsletterProps extends BoxProps {
  newsletterTitle: string;
  newsletterDescription: string;
  formId: string;
  portalId: string;
}

const GRID_TEMPLATE_COLUMNS: GetSprinklesArgs["gridTemplateColumns"] = {
  desktop: 2,
  tablet: 1,
  mobile: 1,
};
export function FooterNewsletter({
  newsletterTitle,
  newsletterDescription,
  formId,
  portalId,
  ...rest
}: FooterNewsletterProps) {
  return (
    <Box
      display="grid"
      gridTemplateColumns={GRID_TEMPLATE_COLUMNS}
      alignItems="start"
      gap="gutterWidth"
      {...rest}
    >
      <SectionHeader
        title={newsletterTitle}
        description={newsletterDescription}
        textAppearance="h3"
      />

      <FormHubspot
        formId={formId}
        portalId={portalId}
        marginTop="spacing2"
        layout="horizontal"
        gap="spacing2"
        analyticsData={{
          type: "newsletter_signup",
          cta: newsletterTitle,
          component: "FooterNewsletter",
        }}
      />
    </Box>
  );
}
