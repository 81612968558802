import React from "react";

import { useResponsiveFieldValue } from "~styles/css_runtime_utils/useResponsiveFieldValue";

import { Box } from "~components/Box";
import { Image } from "~components/Image";
import { PhoneMockup } from "~components/PhoneMockup";

import type { GetSprinklesArgs } from "~styles/getSprinkles.css";
import type {
  StoryblokBlok,
  StoryblokComponentPhoneMockup,
} from "~types/storyblok.types";

export interface GridPhoneMockupsProps extends StoryblokBlok, GetSprinklesArgs {
  caption?: string;
  phoneMockups: Array<StoryblokComponentPhoneMockup>;
}

export function GridPhoneMockups({
  caption,
  phoneMockups,
  gridTemplateColumns,
  gap = "spacing6",
  ...rest
}: GridPhoneMockupsProps) {
  const validGridTemplateColumns =
    useResponsiveFieldValue<GetSprinklesArgs["gridTemplateColumns"]>(
      gridTemplateColumns
    );

  if (phoneMockups?.length > 0) {
    return (
      <Box marginX="auto" marginY="spacing6" width="100%" {...rest}>
        <Box
          gridTemplateColumns={validGridTemplateColumns}
          display="grid"
          gap={gap}
          justifyItems="center"
        >
          {phoneMockups.map((mockup) => {
            return (
              <PhoneMockup key={mockup._uid}>
                <Image {...mockup.asset[0]} />
              </PhoneMockup>
            );
          })}
        </Box>
        {caption && (
          <Box as="figcaption" textAppearance="caption">
            {caption}
          </Box>
        )}
      </Box>
    );
  }

  return null;
}
