import React, { useEffect, useRef } from "react";

import { scroll, timeline } from "motion";

import { vars } from "~styles/themes/theme.css";

import { useResponsiveFieldValue } from "~styles/css_runtime_utils/useResponsiveFieldValue";

import {
  ANIMATED,
  getElemsByMotionAttr,
} from "~utils/motion_one/motion_attribute_selectors";

import { Box } from "~components/Box";
import { StoryblokEditable } from "~components/StoryblokEditable";

import { GridStatsItem } from "./_GridStatsItem";

import type { GridStatsItemProps } from "./_GridStatsItem";
import type { MotionTimelineDefinition } from "~animations/products_addons/types";
import type { BoxProps } from "~components/Box";
import type { VariantBackgroundColorEnum } from "~styles/common/variant.backgroundColor.css";
import type { GetSprinklesArgs } from "~styles/getSprinkles.css";
import type { StoryblokBlok } from "~types/storyblok.types";

/**
 * ------------------------------------------------------------------------------
 * Renders a list of stats with a description and an optional logo.
 * ------------------------------------------------------------------------------
 */

export interface GridStatsProps extends StoryblokBlok, Omit<BoxProps, "size"> {
  background?: VariantBackgroundColorEnum;
  isAnimated?: boolean;
  size?: "sm" | "lg";
  stats: Array<StoryblokBlok & GridStatsItemProps>;

  isGrid?: never;
  hasDivider?: never;
  gridColumnItem?: never;
}

export function GridStats({
  background = "background_charcoal",
  className: userClassName,
  isAnimated = true,
  gridTemplateColumns,
  size = "lg",
  stats,

  // Not used
  isGrid: _isGrid,
  hasDivider: _hasDivider,
  gridColumnItem: _gridColumnItem,

  ...rest
}: GridStatsProps) {
  const hasStats = Array.isArray(stats);
  const refContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (typeof window === "undefined" || !refContainer.current || !isAnimated)
      return;

    const allAnimatedElements = getElemsByMotionAttr(refContainer, "primary");

    if (refContainer.current && allAnimatedElements) {
      const sequence: MotionTimelineDefinition = Array.from(
        allAnimatedElements
      ).map((element, index) => {
        const animOffset = 1 - index / allAnimatedElements.length;

        // if (!element) return null;

        return [
          element,
          {
            opacity: [0, 1],
            scale: [0.8, 1],
            y: [vars.spacing.spacing5, 0],
          },
          {
            at: animOffset,
          },
        ];
      });

      scroll(
        timeline(sequence),

        {
          smooth: 10,
          axis: "y",
          target: refContainer.current,
          offset: ["start end", `center center`],
        }
      );
    }
  });

  const validGridTemplateColumns =
    useResponsiveFieldValue<GetSprinklesArgs["gridTemplateColumns"]>(
      gridTemplateColumns
    );

  return (
    <Box
      display="grid"
      gap="gutterWidth"
      gridTemplateColumns={validGridTemplateColumns}
      ref={refContainer}
      className={userClassName}
      {...rest}
    >
      {hasStats &&
        stats.map((stat, index) => {
          return (
            <StoryblokEditable
              /** Data is static, can key by index */
              // eslint-disable-next-line react/no-array-index-key
              key={`${stat.text}-${index}`}
              {...stat}
            >
              <GridStatsItem
                {...stat}
                background={background}
                data-motion={ANIMATED.primary}
                opacity={isAnimated ? "0" : "1"}
                key={stat.text}
                size={size}
              />
            </StoryblokEditable>
          );
        })}
    </Box>
  );
}
