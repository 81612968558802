/* eslint-disable unused-imports/no-unused-vars */
import React from "react";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";

import { Box } from "~components/Box";
import { ButtonLink } from "~components/ButtonLink";
import { FormHubspotDialog } from "~components/FormHubspotDialog";
import { Grid } from "~components/Grid";
import { IconBrand } from "~components/IconBrand";
import { StoryblokRichText } from "~components/StoryblokRichText";

import { SectionHeader } from "../../components/SectionHeader";

import type { SectionHeaderProps } from "../../components/SectionHeader";
import type { BoxProps } from "~components/Box";
import type { GetSprinklesArgs } from "~styles/getSprinkles.css";
import type {
  StoryblokBlok,
  StoryblokFieldLink,
  StoryblokFieldRichText,
} from "~types/storyblok.types";
import type { DispatchConversionEventArgs } from "~utils/analytics/dispatchConversionEvent";

export interface CtaFooterProps extends StoryblokBlok, BoxProps {
  card1CtaText: string;
  card1Description: StoryblokFieldRichText | string;
  card1Title: string;
  card2CtaText: string;
  card2Description: StoryblokFieldRichText | string;
  card2Link: StoryblokFieldLink;
  card2Title: string;
  conversionType?: DispatchConversionEventArgs["type"] | null;
  formId: string;
  portalId?: string;
  sectionHeader?: Array<StoryblokBlok & SectionHeaderProps>;

  /** @deprecated */
  text?: StoryblokFieldRichText | string;
  /** @deprecated */
  title?: string;
}

const COMMON_CARD_PROPS: BoxProps = {
  padding: "spacing3",
  border: "default",
  // aspectRatio: "wide",
  borderRadius: "md",
  display: "flex",
  flexDirection: "column",
  gap: "gutterWidth",
  className: variantBackgroundColor.background_white,
};

const GRID_TEMPLATE_COLUMNS: GetSprinklesArgs["gridTemplateColumns"] = {
  mobile: 1,
  desktop: 2,
};
/**
 * Renders a centered hubspot form with a title & description.
 * Configurable in the CMS.
 */
export function CtaFooter({
  text,
  title,
  formId,
  portalId,

  card1Title,
  card1Description,
  card1CtaText,
  card2Title,
  card2CtaText,
  card2Description,
  card2Link,

  sectionHeader: sectionHeaderBlokArray,
  conversionType,
  ...rest
}: CtaFooterProps) {
  const [sectionHeader] = sectionHeaderBlokArray || [];

  return (
    <Box maxWidth="gridSpan8" marginX="auto" {...rest}>
      {sectionHeader && (
        <SectionHeader marginBottom="spacing3" {...sectionHeader} />
      )}

      <Grid gridTemplateColumns={GRID_TEMPLATE_COLUMNS}>
        <Box {...COMMON_CARD_PROPS}>
          <IconBrand icon="chat" color="black" />
          <Box as="h3" marginTop="auto" textAppearance="h6">
            {card1Title}
          </Box>
          <StoryblokRichText text={card1Description} />

          <FormHubspotDialog
            hubspotFormId={formId}
            dialogTitle={card1CtaText}
            buttonTitle={card1CtaText}
            conversionType={conversionType || "contact_sales"}
            conversionComponent="CtaFooter"
          />
        </Box>
        <Box {...COMMON_CARD_PROPS}>
          <IconBrand icon="engineering" color="black" />

          <Box as="h3" marginTop="auto" textAppearance="h6">
            {card2Title}
          </Box>
          <StoryblokRichText text={card2Description} />
          <ButtonLink width="max-content" link={card2Link}>
            {card2CtaText}
          </ButtonLink>
        </Box>
      </Grid>
    </Box>
  );
}
