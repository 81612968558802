import React from "react";

import clsx from "clsx";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";

import { Box } from "~components/Box";
import { SvgFlagIcon } from "~components/SvgFlagIcon";
import { SvgLogo } from "~components/SvgLogo";

import * as styles from "./styles.css";

import type { BoxProps } from "~components/Box";
import type { SvgFlagIconProps } from "~components/SvgFlagIcon";
import type { VariantBackgroundColorEnum } from "~styles/common/variant.backgroundColor.css";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface GridStatsItemProps
  extends StoryblokBlok,
    Omit<BoxProps, "size"> {
  background?: VariantBackgroundColorEnum;
  countryCode?: SvgFlagIconProps["countryCode"];
  descriptionLong: string;
  descriptionShort: string;
  logo?: string | null;
  orientation?: "horizontal" | "vertical";
  size?: "sm" | "lg";
  text: string;
}

export function GridStatsItem({
  background = "background_charcoal",
  countryCode,
  descriptionLong,
  descriptionShort,
  logo,
  size,
  text,
  ...rest
}: GridStatsItemProps) {
  return (
    <Box
      width="100%"
      className={styles.outerElement}
      overflow="hidden"
      {...rest}
    >
      <Box
        isolation="isolate"
        position="relative"
        overflow="hidden"
        className={clsx(
          variantBackgroundColor[background],
          styles.getStatClassNames({ appearance: size })
        )}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          gap="spacing1"
          width="100%"
        >
          <Box width="100%" className={styles.stat}>
            {text}
          </Box>
        </Box>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          gap="spacing1"
          width="100%"
        >
          <Box className={styles.descriptionShort}>{descriptionShort}</Box>
          {countryCode && (
            <SvgFlagIcon maxWidth="spacing3" countryCode={countryCode} />
          )}
        </Box>
      </Box>
      <Box className={styles.descriptionLong}>{descriptionLong}</Box>
      {logo && (
        <SvgLogo height="spacing7" svgLogo={logo} color="text_lowContrast" />
      )}
    </Box>
  );
}
