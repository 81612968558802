import { checkHasLength } from "~utils/__deprecated__/checkHasLength";

import type { RefinementListRenderState } from "instantsearch.js/es/connectors/refinement-list/connectRefinementList";
import type { DropdownItemShape } from "~types/global.types";

interface UseAlgoliaRefinementMultiSelectItemsArgs {
  items: RefinementListRenderState["items"];
}

/**
 * Util function to transform Algolia refinements into downshift items. Strips
 * unnecessary keys (e.g. `isRefined`) that can cause downshift to render duplicate items.
 */
export const transformAlgoliaRefinementsToDropdownItems = (
  items: RefinementListRenderState["items"]
): Array<DropdownItemShape> => {
  return items.map((refinement) => {
    return {
      value: refinement.value,
      label: refinement.label,
    };
  });
};

/**
 * Called with an array of possible Algolia search refinements, returns
 * an array of items, and an array of selected items for Downshift.
 */
export function getAlgoliaRefinementsAsDropdownItems({
  items: allAlgoliaRefinements,
}: UseAlgoliaRefinementMultiSelectItemsArgs) {
  if (!checkHasLength(allAlgoliaRefinements))
    return { items: [], selectedItems: [] };

  /** Transform all available refinements into an array of downshift items */

  const items = transformAlgoliaRefinementsToDropdownItems(
    allAlgoliaRefinements
  );

  /** Calculate an array of selected downshift items from algolia refinements */

  const refinedAlgoliaItems = allAlgoliaRefinements.filter((item) => {
    return item.isRefined;
  });

  const selectedItems =
    transformAlgoliaRefinementsToDropdownItems(refinedAlgoliaItems);

  return { items, selectedItems };
}
