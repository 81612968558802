/**
 * ------------------------------------------------------------------------------
 * Abstraction for getting motion attributes for primary/secondary animations by level
 * ------------------------------------------------------------------------------
 */
import { ANIMATED } from "~utils/motion_one/motion_attribute_selectors";

export const getMotionAttrsByLevel = (level: "primary" | "secondary") => {
  return {
    groupMotionAttr:
      level === "primary" ? ANIMATED.groupPrimary : ANIMATED.groupSecondary,
    elementMotionAttr:
      level === "primary" ? ANIMATED.primary : ANIMATED.secondary,
  };
};
