import React from "react";

import { faAngleDown } from "@fortawesome/sharp-regular-svg-icons/faAngleDown";
import { faAngleUp } from "@fortawesome/sharp-regular-svg-icons/faAngleUp";

import { Box } from "~components/Box";

import { AlgoliaRefinementMultiSelect } from "~sections/AlgoliaSearch/SelectAlgoliaRefinement.tsx";

import { useContentSearchContext } from "~context/ContentSearchContext";

import type {
  RefinementListItem,
  RefinementListRenderState,
} from "instantsearch.js/es/connectors/refinement-list/connectRefinementList";
import type { ContentTopic } from "~types/storyblok.types";

/** Content search controls */
export function ContentSearchControls({
  topics,
  filterLabel,
  dropdownPlaceholder = "Select",
}: {
  topics?: Array<ContentTopic>;
  filterLabel?: string;
  dropdownPlaceholder?: string;
}) {
  const { topicsRefinementListProps, clearTopicsRefinementList } =
    useContentSearchContext();

  const validTopics = topicsRefinementListProps?.items?.reduce(
    (validRefinementItems: RefinementListItem[], topicRefinementItem) => {
      const validTopic = topics?.find((topic) => {
        return topic.value === topicRefinementItem.value;
      });

      if (!validTopic) return validRefinementItems;

      validRefinementItems.push({
        ...topicRefinementItem,
        label: validTopic.label,
      });

      return validRefinementItems;
    },
    []
  );

  const validTopicsRefinementListProps = {
    ...topicsRefinementListProps,
    items: validTopics,
  };

  return (
    <Box display="flex" alignItems="center" paddingBottom="spacing5">
      <Box fontWeight="bold" marginRight="spacing1">
        {filterLabel}
      </Box>
      <AlgoliaRefinementMultiSelect
        {...(validTopicsRefinementListProps as RefinementListRenderState)}
        clearRefinements={clearTopicsRefinementList}
        id="topics"
        name={dropdownPlaceholder}
        placeholder={dropdownPlaceholder}
        size="lg"
        inputAppearance="rounded"
        showItemsQuantity={false}
        iconRightClosed={faAngleDown}
        iconRightOpened={faAngleUp}
      />
    </Box>
  );
}
