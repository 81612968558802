import React from "react";

import { recipeMaxWidth } from "~styles/common/recipeMaxWidth.css";
import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";

import { Box } from "~components/Box";
import { ButtonLink } from "~components/ButtonLink";
import { Link } from "~components/Link";
import {
  SelectLanguage,
  type SelectLanguageProps,
} from "~components/SelectLanguage";

import Logomark from "~assets/svg/logo/logomark.svg";
import Logotype from "~assets/svg/logo/logotype.svg";

import { NavDesktop } from "../Nav/desktop";
import { NavMobile } from "../Nav/mobile";
import * as styles from "./styles.css";

import type { NavDesktopProps } from "../Nav/desktop";
import type { NavMobileProps } from "../Nav/mobile";
import type { VariantBackgroundColorEnum } from "~styles/common/variant.backgroundColor.css";
import type {
  StoryblokBlok,
  StoryblokEntryAlternate,
  StoryblokFieldLink,
} from "~types/storyblok.types";

export interface HeaderProps extends StoryblokBlok {
  alternates: Array<StoryblokEntryAlternate>;
  buttonLink?: StoryblokFieldLink;
  buttonText?: string;
  currentLanguage: string;
  headerBackground?: VariantBackgroundColorEnum;
  languages: SelectLanguageProps["languages"];
  logoLink: StoryblokFieldLink;
  mainMenuTitle: string;
  navCategories?: NavDesktopProps["navCategories"] &
    NavMobileProps["navCategories"];
  secondaryButtonLink?: StoryblokFieldLink;
  secondaryButtonText?: string;
}

export function Header({
  buttonLink,
  buttonText,
  currentLanguage,
  languages,
  logoLink,
  navCategories,
  headerBackground = "background_white",
  alternates,
  mainMenuTitle = "Main menu",
  secondaryButtonLink,
  secondaryButtonText,
  ...rest
}: HeaderProps) {
  return (
    <Box
      id="header"
      as="header"
      position="sticky"
      top="0"
      borderBottom="default"
      height="headerHeight"
      __zIndex="10"
      display="flex"
      width="100%"
      className={variantBackgroundColor[headerBackground || "background_white"]}
      {...rest}
    >
      <Box
        width="100%"
        maxWidth="gridSpan12"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        className={recipeMaxWidth({ maxWidth: "gridSpan12" })}
      >
        <Link
          link={logoLink}
          title="Home"
          className={styles.logoWrapper}
          {...rest}
        >
          <Logomark data-testid="logomark" className={styles.logoMark} />
          <Logotype data-testid="logotype" className={styles.logoType} />
        </Link>
        <NavDesktop navCategories={navCategories} />

        <Box display="flex" gap="spacing2" alignItems="center" zIndex="1">
          <SelectLanguage
            currentLanguage={currentLanguage}
            languages={languages}
            alternates={alternates}
          />

          <ButtonLink
            link={secondaryButtonLink}
            className={styles.buttonLinkStyle}
            size="md"
            display={{ mobile: "none", desktop: "flex" }}
            appearance="secondary"
            shouldHaveArrowIcon={false}
          >
            {secondaryButtonText}
          </ButtonLink>
          <ButtonLink
            link={buttonLink}
            className={styles.buttonLinkStyle}
            size="md"
            display={{ mobile: "none", desktop: "flex" }}
          >
            {buttonText}
          </ButtonLink>

          {/** Mobile nav */}

          <NavMobile
            navCategories={navCategories}
            buttonLink={buttonLink}
            buttonText={buttonText}
            mainMenuTitle={mainMenuTitle}
            secondaryButtonLink={secondaryButtonLink}
            secondaryButtonText={secondaryButtonText}
          />
        </Box>
      </Box>
    </Box>
  );
}
