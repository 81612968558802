// https://caniuse.com/mdn-api_intersectionobserver
// based on https://medium.com/the-non-traditional-developer/how-to-use-an-intersectionobserver-in-a-react-hook-9fb061ac6cb5
import { useEffect, useRef, useState } from "react";

type UseIntersectOptions = IntersectionObserverInit;

export function useIntersect({
  root = null,
  rootMargin,
  threshold = 0,
}: UseIntersectOptions): [
  React.MutableRefObject<HTMLElement | null>,
  IntersectionObserverEntry | undefined
] {
  const [IOEntry, setIOEntry] = useState<
    IntersectionObserverEntry | undefined
  >();
  const observedElRef = useRef<HTMLElement | null>(null);
  const observer = useRef<IntersectionObserver>();

  useEffect(() => {
    if (observer.current) {
      //  make sure to disconnect any stale nodes
      observer.current.disconnect();
    }

    //  make sure we're creating new intersection observer
    //  when node or params for observer change
    observer.current = new IntersectionObserver(
      ([e]) => {
        setIOEntry(e);
      },
      {
        root,
        rootMargin,
        threshold,
      }
    );

    if (observedElRef.current) {
      observer.current.observe(observedElRef.current);
    }

    //  cleanup
    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [root, rootMargin, threshold]);

  return [observedElRef, IOEntry];
}
