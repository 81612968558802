import React, { useMemo } from "react";

import { faTimes } from "@fortawesome/sharp-regular-svg-icons/faTimes";
import {
  Close as RadixDialogClose,
  Content as RadixDialogContent,
  Description as RadixDialogDescription,
  Overlay as RadixDialogOverlay,
  Portal as RadixDialogPortal,
  Root as RadixDialogRoot,
  Title as RadixDialogTitle,
  Trigger as RadixDialogTrigger,
} from "@radix-ui/react-dialog";
import { VisuallyHidden as RadixDialogHidden } from "@radix-ui/react-visually-hidden";

import { vars } from "~styles/themes/theme.css";

import { Box } from "~components/Box";
import { Button } from "~components/Button";

import Logomark from "~assets/svg/logo/logomark.svg";
import Logotype from "~assets/svg/logo/logotype.svg";

import * as styles from "./styles.css";

import type { ElementType, JSXElementConstructor, ReactNode } from "react";
import type { ButtonProps } from "~components/Button";
import type { GetSprinklesArgs } from "~styles/getSprinkles.css";

export interface DialogProps extends GetSprinklesArgs {
  buttonProps?: ButtonProps;
  buttonTitle?: string;
  callbackOnOpenChange?: (isOpen: boolean) => void;
  children: ReactNode | Array<ReactNode>;
  customButtonElement?: ElementType;
  dialogDescription?: string;
  dialogTitle: string;
  FooterComponent?: JSXElementConstructor<unknown>;
  isLogoVisible?: boolean;
  isTitleVisible?: boolean;
  isOverNavbar?: boolean;
  className?: string;
}

export function Dialog({
  buttonTitle,
  customButtonElement,
  dialogTitle,
  isLogoVisible,
  callbackOnOpenChange,
  dialogDescription,
  children,
  buttonProps,
  FooterComponent,
  isTitleVisible = true,
  isOverNavbar,
  className,
  ...rest
}: DialogProps) {
  const ButtonElement = customButtonElement || Button;

  const TitleComponent = useMemo(() => {
    return isTitleVisible ? RadixDialogTitle : RadixDialogHidden;
  }, [isTitleVisible]);

  return (
    <Box className={className}>
      <RadixDialogRoot onOpenChange={callbackOnOpenChange}>
        <Box {...rest}>
          <RadixDialogTrigger asChild>
            <ButtonElement {...buttonProps}>{buttonTitle}</ButtonElement>
          </RadixDialogTrigger>

          <RadixDialogPortal>
            <RadixDialogOverlay
              className={styles.getOverlayStyle({ isOverNavbar })}
            />
            <RadixDialogContent className={styles.contentOuter}>
              {/**
               * DIALOG HEADER
               *
               * Title is required for screen-readers. If we need
               * a dialog without a title, we should edit the code
               * to support a `isVisuallyHidden` prop, rather than
               * conditionally render it.
               */}
              <div className={styles.titleWrapper}>
                {isLogoVisible && (
                  <>
                    <Logomark
                      data-testid="logomark"
                      className={styles.logoMark}
                    />
                    <Logotype
                      data-testid="logotype"
                      className={styles.logoType}
                    />
                  </>
                )}

                <TitleComponent asChild>
                  <Box textAppearance="body_md" fontWeight="bold">
                    {dialogTitle}
                  </Box>
                </TitleComponent>

                <RadixDialogClose asChild>
                  <Button
                    appearance="secondary"
                    marginLeft="auto"
                    size="md_square"
                    iconLeft={faTimes}
                  />
                </RadixDialogClose>
              </div>
              {dialogDescription && (
                <RadixDialogDescription>
                  <Box as="p" textAlign="center">
                    {dialogDescription}
                  </Box>
                </RadixDialogDescription>
              )}

              {/** Scrollable dynamic content */}
              <Box className={styles.contentInner}>{children}</Box>
              {/**
               * DYNAMIC FOOTER COMPONENT
               *
               * Render a custom footer component that is outside the
               * scrollable area of the inner content, and positioned at
               * the bottom of the dialog.
               */}
              {FooterComponent && (
                <Box
                  __padding={vars.spacing.mobileWindowMargin}
                  borderTop="default"
                >
                  <FooterComponent />
                </Box>
              )}
            </RadixDialogContent>
          </RadixDialogPortal>
        </Box>
      </RadixDialogRoot>
    </Box>
  );
}
