import React from "react";

import { useResponsiveFieldValue } from "~styles/css_runtime_utils/useResponsiveFieldValue";

import { checkHasLength } from "~utils/__deprecated__/checkHasLength";

import { Box } from "~components/Box";
import { Image } from "~components/Image";

import type { GetSprinklesArgs } from "~styles/getSprinkles.css";
import type {
  StoryblokBlok,
  StoryblokComponentImage,
} from "~types/storyblok.types";

export interface GridImagesProps extends StoryblokBlok, GetSprinklesArgs {
  images?: Array<StoryblokComponentImage>;
  imagesMaxWidth?: GetSprinklesArgs["maxWidth"];
  imagesOpacity?: GetSprinklesArgs["opacity"];
}

/** Renders a grid of images, e.g. for vector logos or photography layouts. */
export function GridImages({
  images: imagesArray,
  imagesMaxWidth,
  imagesOpacity,

  gridTemplateColumns,
  ...rest
}: GridImagesProps) {
  const hasImages = imagesArray && checkHasLength(imagesArray);

  const validResponsiveGridTemplateColumns =
    useResponsiveFieldValue<GetSprinklesArgs["gridTemplateColumns"]>(
      gridTemplateColumns
    );

  if (!hasImages) return null;

  return (
    <Box
      display="grid"
      gridTemplateColumns={validResponsiveGridTemplateColumns}
      gap="gutterWidth"
      alignItems="center"
      justifyItems="center"
      {...rest}
    >
      {imagesArray?.map((image) => {
        return (
          <Image
            {...image}
            key={image._uid}
            data-testid="image-grid-item"
            maxWidth={imagesMaxWidth}
            opacity={imagesOpacity}
          />
        );
      })}
    </Box>
  );
}
