/**
 * ------------------------------------------------------------------------------
 * Abstraction for animating primary elements
 * ------------------------------------------------------------------------------
 */
import { animateElementsInGroup } from "./_animateElementsWithinGroup";
import { getMotionAttrsByLevel } from "./_getMotionAttributesByLevel";

import type {
  AnimationDirection,
  AnimationOrientation,
  MotionGroupsDict,
} from "../types";
import type { RefObject } from "react";

interface AnimatePrimaryElementsArgs {
  ref: RefObject<HTMLDivElement>;
  orientation: AnimationOrientation;
  animationDirection: AnimationDirection;
  motionGroups: MotionGroupsDict;
  current: number;
  previous: number;
  level: "primary" | "secondary";
}

export const animateElementGroups = ({
  animationDirection,
  motionGroups,
  current,
  level,
  orientation,
  previous,
}: AnimatePrimaryElementsArgs) => {
  /** Get 'data-motion' attributes  we'll use to select elements to animate */
  const { elementMotionAttr } = getMotionAttrsByLevel(level);

  const animationScale = level === "primary" ? "md" : "sm";

  /** Iterate over each group, and animate elements in the current and previous groups only* */
  Object.entries(motionGroups)?.forEach(([groupKey, groupElements]) => {
    if (typeof current !== "undefined" && groupKey === current.toString()) {
      groupElements?.forEach((group) => {
        animateElementsInGroup({
          elementAnimationDirection: "in",
          animationDirection,
          elementMotionAttr,
          group,
          orientation,
          scale: animationScale,
        });
      });
    }
    if (
      typeof previous !== "undefined" &&
      current !== previous &&
      groupKey === previous.toString()
    ) {
      groupElements?.forEach((group) => {
        animateElementsInGroup({
          elementAnimationDirection: "out",
          animationDirection,
          elementMotionAttr,
          group,
          orientation,
          scale: animationScale,
        });
      });
    }
  });
};
