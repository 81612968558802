/**
 * ------------------------------------------------------------------------------
 * Abstraction for animating elements within a group
 * ------------------------------------------------------------------------------
 */
import { animate, spring, stagger } from "motion";

import { getMotionSelector } from "~utils/motion_one/motion_attribute_selectors";

import { MOTION_SPRING_LONG, MOTION_SPRING_SHORT } from "~config/SPRING_CONFIG";

import { STAGGER } from "../config";
import { getTranslationKeyframes } from "./_getTranslationKeyframes";

import type {
  AnimationDirection,
  AnimationOrientation,
  ElementAnimationDirection,
} from "../types";
import type { MotionAttrEnum } from "~utils/motion_one/motion_attribute_selectors";

interface AnimateElementsInGroupArgs {
  elementAnimationDirection: ElementAnimationDirection;
  animationDirection: AnimationDirection;
  elementMotionAttr: Extract<MotionAttrEnum, "primary" | "secondary">;
  group?: Element;
  orientation: AnimationOrientation;
  scale: "md" | "sm";
  callbackOnComplete?: () => void;
}

export const animateElementsInGroup = async ({
  elementAnimationDirection,
  animationDirection,
  elementMotionAttr,
  group,
  orientation,
  scale,
}: AnimateElementsInGroupArgs) => {
  if (!group) return;

  /** Animated elements will be grouped a `data-motion=group$1` attribute  */

  const currentElements = group.querySelectorAll(
    getMotionSelector(elementMotionAttr)
  );

  if (!currentElements || currentElements?.length < 1) return;

  animate(
    currentElements,
    {
      opacity: elementAnimationDirection === "in" ? [0, 1] : [1, 0],
      zIndex: elementAnimationDirection === "in" ? [1, 1] : [0, 0],
      transform: getTranslationKeyframes({
        animationDirection,
        elementAnimationDirection,
        scale,
        orientation,
      }),
    },
    {
      delay: stagger(STAGGER, { easing: "ease-in" }),
      easing: spring(scale === "md" ? MOTION_SPRING_LONG : MOTION_SPRING_SHORT),
    }
  );
};
