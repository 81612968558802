// Values below updated to match src/styles/global/css_vars_media_queries.ts
export const SCREEN_SIZES = {
  DESKTOP: 992,
  TABLET: 720,
  MOBILE: 719,
};

/** Union of all screen size options. */
export type ScreenSize = keyof typeof SCREEN_SIZES;

/** Create CSS media query for min and/or max breakpoint. */
export function getMediaQuery({
  min,
  max,
}: {
  /** The min breakpoint name. */
  min?: ScreenSize;
  /** The max breakpoint name. */
  max?: ScreenSize;
}) {
  const minBreakpoint = min && SCREEN_SIZES[min];
  const maxBreakpoint = max && SCREEN_SIZES[max] - 1;

  if (min && minBreakpoint && max && maxBreakpoint) {
    return `(min-width: ${minBreakpoint}px) and (max-width: ${maxBreakpoint}px)`;
  }

  if (min && minBreakpoint) {
    return `(min-width: ${minBreakpoint}px)`;
  }

  if (max && maxBreakpoint) {
    return `(max-width: ${maxBreakpoint}px)`;
  }

  return "";
}
