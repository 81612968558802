import { useMemo } from "react";

import type { CommonPageStoryProps } from "~types/storyblok.types";

interface UsePageMetadataArgs {
  story?: CommonPageStoryProps;
}

export function usePageMetadata({ story }: UsePageMetadataArgs) {
  return useMemo(() => {
    const { pageMetadata } = story || {};

    return pageMetadata;
  }, [story]);
}
