import React from "react";

import { HorizontalDivider } from "~components/HorizontalDivider";

import { LayoutSection } from "~sections/LayoutSection";

import { FooterNewsletter } from "./FooterNewsletter";
import { FooterSocialLegal } from "./FooterSocialLegal";
import { FooterLinks } from "./_FooterLinks";

import type { IconName } from "@fortawesome/fontawesome-svg-core";
import type { ButtonLinkProps } from "~components/ButtonLink";
import type { StoryblokBlok, StoryblokFieldLink } from "~types/storyblok.types";

export type FooterSocialButton = StoryblokBlok & {
  link: StoryblokFieldLink;
  fontAwesomeIcon: IconName;
  label: string;
};

export type FooterLinkSection = StoryblokBlok & {
  title?: string;
  items?: Array<ButtonLinkProps>;
};

export interface FooterProps extends StoryblokBlok {
  newsletterDescription?: string;
  newsletterFormId?: string;
  newsletterPortalId?: string;
  newsletterTitle?: string;
  regulatoryDisclaimer?: string;
  sections?: Array<FooterLinkSection>;
  socialButtons?: Array<FooterSocialButton>;
}

export function Footer({
  newsletterDescription,
  newsletterFormId,
  newsletterPortalId,
  newsletterTitle,
  regulatoryDisclaimer,
  sections,
  socialButtons,
  ...rest
}: FooterProps) {
  return (
    <LayoutSection
      isBorderRounded
      background="background_charcoal"
      borderBottom="default"
      as="footer"
      {...rest}
    >
      {/**
       * ------------------------------------------------------------------------------
       * Newsletter
       * ------------------------------------------------------------------------------
       */}

      {newsletterTitle &&
        newsletterDescription &&
        newsletterFormId &&
        newsletterPortalId && (
          <FooterNewsletter
            portalId={newsletterPortalId}
            formId={newsletterFormId}
            newsletterTitle={newsletterTitle}
            newsletterDescription={newsletterDescription}
          />
        )}
      <HorizontalDivider />

      {/**
       * ------------------------------------------------------------------------------
       * Links
       * ------------------------------------------------------------------------------
       */}

      <FooterLinks sections={sections} />
      <HorizontalDivider />

      {/**
       * ------------------------------------------------------------------------------
       * Social & legal
       * ------------------------------------------------------------------------------
       */}

      {regulatoryDisclaimer && socialButtons && (
        <FooterSocialLegal
          regulatoryDisclaimer={regulatoryDisclaimer}
          socialButtons={socialButtons}
        />
      )}
    </LayoutSection>
  );
}
