/**
 * ------------------------------------------------------------------------------
 * Abstraction to determine carousel movement animationDirection
 * ------------------------------------------------------------------------------
 */
import type { AnimationDirection } from "../types";

export const getAnimationDirection = (
  current: number | undefined,
  previous: number | undefined
): AnimationDirection => {
  /**
   * If either value is undefined, or the current
   * is greater than the previous, we can assume we
   * are moving forwards in whatever carousel or
   * accordion is consuming this hook
   */

  if (
    typeof previous !== "number" ||
    typeof current !== "number" ||
    current > previous
  ) {
    return "forward";
  }

  return "backward";
};
