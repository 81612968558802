import { useRefinementList } from "react-instantsearch";

import { useDebugRenderCounter } from "~hooks/useDebugRenderCounter";

import type { UseRefinementListProps } from "react-instantsearch";

export function useVirtualRefinementList(props: UseRefinementListProps) {
  useDebugRenderCounter("virtual refinement list");

  return useRefinementList(
    {
      ...props,
      sortBy: ["isRefined"],
    },
    {
      $$widgetType: "ais.refinementList",
    }
  );
}
