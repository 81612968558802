import { useEffect, useState } from "react";

import type { HLJSApi } from "highlight.js";

const LAZY_LOAD_LANGUAGES_MAP = {
  rust: import("highlight.js/lib/languages/rust"),
  javascript: import("highlight.js/lib/languages/javascript"),
  json: import("highlight.js/lib/languages/json"),
  bash: import("highlight.js/lib/languages/bash"),
};

type LanguageMap = keyof typeof LAZY_LOAD_LANGUAGES_MAP;

interface UseHighlightedCodeArgs {
  /** Programming language to load from highlight.js for syntax highlighting. */
  language?: LanguageMap;
  /** Plain text code snippet to highlight syntax of. */
  code?: string;
  /** Whether to return line numbers in the formatted html. */
  hasLineNumbers?: boolean;
}

const NEWLINE_REGEX = /\n/g;

/**  util to prefix newline (\n) characters in html source code with line number */
function addSourceLineNumbers(html: string) {
  let line = 1;
  const newHtml = `<span class="lineNumber">${line}</span>${html}`;

  return newHtml.replace(NEWLINE_REGEX, () => {
    line += 1;

    return `\n<span class="lineNumber">${line}</span>`;
  });
}

/**
 *  Hook that loads highlight.js, loads any syntax highlighting files needed by
 *  passed language and then returns code as highlighted/formatted HTML string
 */
export function useHighlightedCode({
  language,
  code,
  hasLineNumbers,
}: UseHighlightedCodeArgs) {
  const [cachedHljsLibrary, setCachedHljsLibrary] = useState<HLJSApi>();
  const [cachedLanguages, setCachedLanguages] = useState<Array<string>>([]);

  const [highlightedCode, setHighlightedCode] = useState("");

  // Load lib and set initial cachedLanguages
  useEffect(() => {
    import("highlight.js/lib/core")
      .then(({ default: lib }) => {
        if (lib) {
          lib.configure({});
          setCachedHljsLibrary(lib);
          setCachedLanguages(lib.listLanguages());
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  /**
   * Load language and syntax highlight
   * We are doing a nasty relative  import  here as it is the only
   * way we can programmatically address the individual languages in
   * the highlight js node module folder
   * ToDo: [MW-1119] Fix highlight JS implementation
   */
  useEffect(() => {
    if (cachedHljsLibrary && language && cachedLanguages && code) {
      if (!cachedLanguages.includes(language)) {
        LAZY_LOAD_LANGUAGES_MAP[language].then((languageFile) => {
          cachedHljsLibrary.registerLanguage(language, languageFile.default);
          setCachedLanguages(cachedHljsLibrary.listLanguages());
        });
      } else {
        let { value: html } = cachedHljsLibrary.highlight(code, { language });

        if (hasLineNumbers) {
          html = addSourceLineNumbers(html);
        }

        setHighlightedCode(html);
      }
    }
  }, [cachedHljsLibrary, cachedLanguages, code, hasLineNumbers, language]);

  return [highlightedCode];
}
