import { useMemo } from "react";

import { parseStoryblokEntry } from "~utils/storyblok/parseStoryblokEntry";

import type { GlobalPageProps } from "~types/global.types";
import type { StoryblokEntryWithStringifiedContent } from "~types/storyblok.types";

/**
 * @todo [MW-1310] Reduce the amount of type-casting in `usePageData` when working with Storyblok JSON
 */
export function usePageData<TPropsShape extends GlobalPageProps>(
  props: TPropsShape
) {
  const { data, pageContext } = props;
  const { story } = data || {};
  const pagePath = props.data?.story?.full_slug;

  const parsedStory = useMemo(() => {
    if (!story) return undefined;

    return parseStoryblokEntry(
      "story",
      story as StoryblokEntryWithStringifiedContent
    );
  }, [story]);

  return {
    story: parsedStory as Exclude<
      TPropsShape["data"]["story"],
      StoryblokEntryWithStringifiedContent
    >,
    pageContext: pageContext as TPropsShape["pageContext"],
    data: data as TPropsShape["data"],
    pagePath,
  };
}
