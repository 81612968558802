/**
 * ------------------------------------------------------------------------------
 * Util for getting all grouped elements as dictionary containing list of Nodes
 * ------------------------------------------------------------------------------
 */
import groupBy from "lodash.groupby";

import type { MotionGroupsDict } from "../types";
import type { RefObject } from "react";

export const getAllMotionGroups = (
  ref?: RefObject<HTMLDivElement>
): MotionGroupsDict | undefined => {
  if (!ref?.current) return undefined;

  const allGroupElements = ref.current.querySelectorAll("[data-motion-group]");

  if (!allGroupElements || allGroupElements?.length < 1) return undefined;

  return groupBy(allGroupElements, (element) => {
    return element.getAttribute("data-motion-group");
  });
};
