import React from "react";

import clsx from "clsx";

import { getSprinkles } from "~styles/getSprinkles.css";

import { Box } from "~components/Box";
import { Link } from "~components/Link";

import { footerLinkStyle } from "./styles.css";

import type { FooterProps } from ".";
import type { BoxProps } from "~components/Box";
import type { GetSprinklesArgs } from "~styles/getSprinkles.css";
import type { varsGridTemplateColumns } from "~styles/vars/vars.grid.css";

interface FooterLinksProps extends BoxProps {
  sections?: FooterProps["sections"];
}

const getFooterLinkGridTemplateColumns = (
  length: number
): GetSprinklesArgs["gridTemplateColumns"] => {
  /** @todo [MW-1315] Avoid type casting in `getFooterLinkGridTemplateColumns` */

  return {
    desktop: length > 6 ? 6 : (length as keyof typeof varsGridTemplateColumns),
    tablet: length > 4 ? 4 : (length as keyof typeof varsGridTemplateColumns),
    mobile: length > 2 ? 2 : (length as keyof typeof varsGridTemplateColumns),
  };
};

export function FooterLinks({ sections, ...rest }: FooterLinksProps) {
  if (!Array.isArray(sections) || sections.length < 1) return null;

  return (
    <Box
      display="grid"
      gap="gutterWidth"
      gridTemplateColumns={getFooterLinkGridTemplateColumns(sections.length)}
      {...rest}
    >
      {sections.map((section, index) => {
        if (!section.title || !Array.isArray(section.items)) return null;

        return (
          <Box
            /** Data is static, can key by index */
            // eslint-disable-next-line react/no-array-index-key
            key={`${section.title}-${index}`}
          >
            <Box
              textAppearance="prefix_md"
              fontWeight="semibold"
              color="text_highContrast"
              textTransform="uppercase"
              marginBottom="spacing2"
            >
              {section.title}
            </Box>

            {section.items.map((link, subIndex) => {
              if (!link.link && !link.title) {
                return null;
              }

              return (
                <Link
                  className={clsx(
                    footerLinkStyle,
                    getSprinkles({
                      textAppearance: "body_md",
                      marginBottom: "spacing1",
                      display: "block",
                    })
                  )}
                  link={link.link}
                  /** Data is static, can key by index */
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${link.children?.toString()}-${subIndex}`}
                >
                  {link.children}
                </Link>
              );
            })}
          </Box>
        );
      })}
    </Box>
  );
}
