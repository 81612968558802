import React from "react";

import { AccordionRichText } from "~components/AccordionRichText";

import type { AccordionRichTextProps } from "~components/AccordionRichText";
import type { BoxProps } from "~components/Box";

export interface AccordionFrequentlyAskedQuestionsProps
  extends Omit<BoxProps, "value" | "defaultValue">,
    Omit<AccordionRichTextProps, "accordionItems" | "children"> {
  questions: AccordionRichTextProps["accordionItems"];
}

export function AccordionFrequentlyAskedQuestions({
  questions,
  className: userClassName,
  ...rest
}: AccordionFrequentlyAskedQuestionsProps) {
  const hasQuestions = questions && questions.length > 0;
  if (!hasQuestions) return null;
  // ToDo: [MW-1068] [TS/VE] Create JSON schema for Q&A component

  return (
    <AccordionRichText
      className={userClassName}
      accordionItems={questions}
      {...rest}
    />
  );
}
