import React, { useState } from "react";

import { useHits } from "react-instantsearch";

import { vars } from "~styles/themes/theme.css";

import { useStoryblokRelations } from "~hooks/useStoryblokRelations";

import { smoothScrollToElement } from "~utils/link_utils/smoothScrollToElement";
import { transformStoryblokEntryApiToCard } from "~utils/transformers/transformStoryblokEntryApiToCard";
import { transformStoryblokEntryNodeToCard } from "~utils/transformers/transformStoryblokEntryNodeToCard";

import { PaginationSinglePage } from "~components/PaginationSinglePage";

import { GridCards } from "~sections/GridCards";
import { divideAllContentsIntoPages } from "~sections/RelationsCards/pagination";

import type { AlgoliaHit } from "instantsearch.js";
import type { CardProps } from "~components/Card";
import type { GridCardsProps } from "~sections/GridCards";

// const NO_MATCH_HEADING = "We couldn't find a match for that.";
// const NO_MATCH_TEXT =
//   "Maybe try changing your filters or search terms. You could also go back and see all open jobs.";
// const NO_MATCH_BUTTON_TEXT = "Clear filters";

export interface ContentSearchHit extends AlgoliaHit {
  id: string;
  topics: Array<string>;
}

export interface ContentSearchResultsProps {
  gridCardsProps: GridCardsProps;
}

export function ContentSearchResults({
  gridCardsProps,
}: ContentSearchResultsProps) {
  const { hits } = useHits<ContentSearchHit>();

  const content = hits.map((hit) => {
    return hit.id;
  });

  const cards: Array<CardProps> =
    useStoryblokRelations<CardProps>({
      content,
      transformApiEntry: transformStoryblokEntryApiToCard,
      transformGraphQlNode: transformStoryblokEntryNodeToCard,
      isAlphabetized: false,
      storyblokRelationsNodeType: "paginationItems",
      hits,
    }) ?? [];

  const [currentPage, setCurrentPage] = useState(1);

  const cardPages = divideAllContentsIntoPages(cards, 12);

  const paginationContainerID = "pagination-container";
  const onPageChange = (page: number) => {
    setCurrentPage(page);
    smoothScrollToElement(
      `#${paginationContainerID}`,
      vars.spacing.spacing10.toString()
    );
  };

  return (
    <>
      <GridCards
        id={paginationContainerID}
        {...gridCardsProps}
        cards={cardPages[currentPage - 1]}
      />
      <PaginationSinglePage
        marginY="spacing10"
        pageCount={cardPages.length}
        currentPage={currentPage}
        onPageChange={onPageChange}
      />
    </>
  );
}
