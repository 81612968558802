import { useEffect, useRef } from "react";

const ENV = process.env.GATSBY_ENV || "development";

export function useDebugRenderCounter(label: string) {
  const renderCounter = useRef(0);

  useEffect(() => {
    if (ENV === "production") return;

    renderCounter.current += 1;
    console.info("debug ~ renderCounter.current:", renderCounter.current);
  }, [label]);
}
