import React from "react";

import clsx from "clsx";

import { useHighlightedCode } from "../useHighlightedCode";
import * as styles from "./styles.css";

import type { StoryblokBlok } from "~types/storyblok.types";

export interface CodeSyntaxHighlightedProps extends StoryblokBlok {
  /** Code sample as plain text string. */
  code?: string;
  /** Programming language for highlight.js */
  language?: "javascript" | "json" | "bash" | "rust";
  /** Whether to render line numbers. */
  hasLineNumbers?: boolean;
  /** Whether to wrap text or allow scrolling horizontally. */
  shouldntWrap?: boolean;
}

/**
 * Renders syntax highlighted code using Highlight.js.
 * composed as part of `CodeSampleWindow`.
 */
export function CodeSyntaxHighlighted({
  code,
  language,
  hasLineNumbers,
  shouldntWrap,
}: CodeSyntaxHighlightedProps) {
  const [highlightedCode] = useHighlightedCode({
    language,
    code,
    hasLineNumbers,
  });

  const classNames = clsx(styles.sourceCode, {
    [styles.shouldntWrap]: shouldntWrap,
  });

  if (highlightedCode) {
    return (
      <pre className={classNames}>
        {/* eslint-disable-next-line react/no-danger */}
        <code dangerouslySetInnerHTML={{ __html: highlightedCode }} />
      </pre>
    );
  }

  return null;
}
