import React, { useCallback, useEffect, useMemo, useState } from "react";

import { SelectMultiple } from "~components/Select/SelectMultiple";

import { transformAlgoliaRefinementsToDropdownItems } from "../getAlgoliaRefinementsAsDownshiftItems";
import { handleAlgoliaRefinementSelection } from "./handleSelection";

import type { UseMultipleSelectionStateChange } from "downshift";
import type { ClearRefinementsRenderState } from "instantsearch.js/es/connectors/clear-refinements/connectClearRefinements";
import type { RefinementListRenderState } from "instantsearch.js/es/connectors/refinement-list/connectRefinementList";
import type { SelectMultipleProps } from "~components/Select/SelectMultiple";
import type { DropdownItemShape } from "~types/global.types";

interface AlgoliaRefinementMultiSelectProps
  extends Omit<SelectMultipleProps, "items">,
    Omit<
      RefinementListRenderState,
      | "hasExhaustiveItems"
      | "createURL"
      | "sendEvent"
      | "searchForItems"
      | "isFromSearch"
      | "canRefine"
    > {
  clearRefinements: ClearRefinementsRenderState["refine"];
  showItemsQuantity?: boolean;
}

export function AlgoliaRefinementMultiSelect({
  items: allAvailableRefinements,
  refine: refineSearchByAttribute,
  clearRefinements,
  placeholder,
  showItemsQuantity = true,
  ...rest
}: AlgoliaRefinementMultiSelectProps) {
  /** State to manage available refinements as downshift items */

  const [downshiftItems, setDropdownItems] = useState(
    transformAlgoliaRefinementsToDropdownItems(allAvailableRefinements)
  );

  /**
   * refinements may load in asynchronously, so we sync them to state here.
   * Do this as infrequently as possible to reduce unnecessary computation,
   * only when the total number of available refinements changes.
   */

  useEffect(() => {
    if (allAvailableRefinements.length !== downshiftItems.length) {
      setDropdownItems(
        transformAlgoliaRefinementsToDropdownItems(allAvailableRefinements)
      );
    }
  }, [allAvailableRefinements, downshiftItems.length]);

  /**
   * Transform current refinements into list of downshift items for
   * controlled `selectedItems` prop
   */

  const selectedItems = useMemo(() => {
    const refinedAlgoliaItems = allAvailableRefinements.filter((item) => {
      return item.isRefined;
    });

    return transformAlgoliaRefinementsToDropdownItems(refinedAlgoliaItems);
  }, [allAvailableRefinements]);

  /** Handler to refine algolia search based on downshift multi selection */

  const handleSelection = useCallback(
    (changes: UseMultipleSelectionStateChange<DropdownItemShape>) => {
      return handleAlgoliaRefinementSelection({
        changes,
        refineSearchByAttribute,
        clearRefinements,
      });
    },
    [refineSearchByAttribute, clearRefinements]
  );

  const quantityPlaceholder = `${placeholder} (${downshiftItems.length} item${
    downshiftItems.length === 1 ? "" : "s"
  })`;

  return (
    <SelectMultiple
      {...rest}
      isFilterable
      items={downshiftItems}
      initialSelectedItems={selectedItems}
      onChange={handleSelection}
      placeholder={showItemsQuantity ? quantityPlaceholder : placeholder}
    />
  );
}
