import React from "react";

import { checkHasLength } from "~utils/__deprecated__/checkHasLength";

import { Box } from "~components/Box";

import { GridPeopleItem } from "./_GridPeopleItem";

import type { GridPeopleItemProps } from "./_GridPeopleItem";
import type { GetSprinklesArgs } from "~styles/getSprinkles.css";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface GridPeopleProps
  extends StoryblokBlok,
    Omit<GetSprinklesArgs, "gridTemplateColumns"> {
  items?: Array<GridPeopleItemProps>;
}
export function GridPeople({ items, ...rest }: GridPeopleProps) {
  const hasItems = items && checkHasLength(items);

  return (
    <Box
      display="grid"
      // gridTemplateColumns={validGridTemplateColumns}
      data-testid="people-grid"
      gap="spacing2"
      maxWidth="gridSpan12"
      marginBottom="spacing6"
      {...rest}
    >
      {hasItems &&
        items?.map((child) => {
          return <GridPeopleItem key={child.name} {...child} />;
        })}
    </Box>
  );
}
