import React, { Suspense } from "react";

import { lazily } from "react-lazily";

import type { CodeSampleWindowProps } from ".";

function LazyCodeSampleWindow(props: CodeSampleWindowProps) {
  const { CodeSampleWindow } = lazily(() => {
    return import(".");
  });

  return (
    <Suspense fallback={null}>
      <CodeSampleWindow {...props} />
    </Suspense>
  );
}

export default LazyCodeSampleWindow;
