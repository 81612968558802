import algoliasearch from "algoliasearch";

import type { SearchClient } from "algoliasearch";

const GATSBY_ALGOLIA_APP_ID = process.env.GATSBY_ALGOLIA_APP_ID || "";
const GATSBY_ALGOLIA_SEARCH_KEY = process.env.GATSBY_ALGOLIA_SEARCH_KEY || "";

export const searchClient: SearchClient = algoliasearch(
  GATSBY_ALGOLIA_APP_ID,
  GATSBY_ALGOLIA_SEARCH_KEY
);
