import React from "react";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";
import { getSprinkles } from "~styles/getSprinkles.css";

import { Avatar } from "~components/Avatar";
import { Box } from "~components/Box";
import { SvgLogo } from "~components/SvgLogo";

import type { SvgLogoProps } from "~components/SvgLogo";
import type {
  StoryblokBlok,
  StoryblokFieldMedia,
} from "~types/storyblok.types";

export interface GridPeopleItemProps extends StoryblokBlok {
  image?: StoryblokFieldMedia;
  jobTitle?: string;
  logo?: SvgLogoProps["svgLogo"];
  name?: string;
  quote?: string;
}

const LOGO_CLASS_NAMES = getSprinkles({
  height: "spacing6",
});

export function GridPeopleItem({
  image,
  name,
  logo,
  jobTitle,
  ...rest
}: GridPeopleItemProps) {
  return (
    <Box
      className={variantBackgroundColor.background_sand}
      borderRadius="sm"
      padding="spacing3"
      border="default"
      display="flex"
      gap="gutterWidth"
      textAppearance="body_md"
      alignItems="start"
      overflow="hidden"
      {...rest}
    >
      {image && <Avatar width="spacing10" height="spacing10" image={image} />}

      <Box marginY="auto">
        {name && <b>{name}</b>}
        {jobTitle && <div>{jobTitle}</div>}
        {logo && <SvgLogo className={LOGO_CLASS_NAMES} svgLogo={logo} />}
      </Box>
    </Box>
  );
}
