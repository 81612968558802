import React, { useCallback } from "react";

import { useSearchBox } from "react-instantsearch";

import { Input } from "~components/Input";

import type { FormEvent } from "react";
import type { InputProps } from "~components/Input";

type AlgoliaSearchInputProps = InputProps & {
  currentRefinement?: string;
};

export function AlgoliaSearchInput({ ...rest }: AlgoliaSearchInputProps) {
  const { refine, query } = useSearchBox({});

  const handleRefine = useCallback(
    (event: FormEvent<HTMLInputElement>) => {
      return refine(event.currentTarget.value);
    },
    [refine]
  );

  return (
    <Input type="search" onChange={handleRefine} value={query} {...rest} />
  );
}
