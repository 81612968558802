import React, { createRef, useEffect, useState } from "react";

import { useResponsiveFieldValue } from "~styles/css_runtime_utils/useResponsiveFieldValue";

import { checkHasLength } from "~utils/__deprecated__/checkHasLength";

import { Box } from "~components/Box";
import { Button } from "~components/Button";

import { CodeSyntaxHighlighted } from "./CodeSyntaxHighlighted";
import * as styles from "./styles.css";

import type { CodeSyntaxHighlightedProps } from "./CodeSyntaxHighlighted";
import type { GetSprinklesArgs } from "~styles/getSprinkles.css";
import type {
  StoryblokBlok,
  StoryblokFieldResponsiveValue,
} from "~types/storyblok.types";

interface CodeSampleWindowTab extends StoryblokBlok {
  title: string;
  code?: Array<StoryblokBlok & CodeSyntaxHighlightedProps>;
}

export interface CodeSampleWindowProps
  extends Omit<GetSprinklesArgs, "display">,
    StoryblokBlok {
  display: StoryblokFieldResponsiveValue & GetSprinklesArgs["display"];
  tabs?: Array<CodeSampleWindowTab>;
}

/**
 * Renders a mock desktop window with tabbed code
 *  samples, with syntax highlighting from highlight.js
 */
export function CodeSampleWindow({
  tabs,
  display,
  ...rest
}: CodeSampleWindowProps) {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const tabRef = createRef<HTMLDivElement>();

  const validResponsiveDisplayProps =
    useResponsiveFieldValue<GetSprinklesArgs["display"]>(display);

  useEffect(() => {
    if (tabRef.current) {
      tabRef.current.scrollTop = 0;
    }
  }, [activeTabIndex, tabRef]);

  const hasTabs = Array.isArray(tabs);
  const currentTab = tabs && tabs[activeTabIndex];

  return (
    <Box
      marginY="spacing6"
      data-testid="source-code-wrapper"
      className={styles.sourceCodeWindow}
      display={validResponsiveDisplayProps}
      {...rest}
    >
      <div className={styles.tabBar}>
        {hasTabs &&
          tabs?.map((tab, index) => {
            return (
              <Button
                name={tab.title}
                appearance="uiLink"
                onClick={() => {
                  setActiveTabIndex(index);
                }}
                key={tab._uid}
              >
                {tab.title}
              </Button>
            );
          })}
      </div>

      <div className={styles.tabContent} ref={tabRef}>
        {currentTab &&
          checkHasLength(currentTab.code) &&
          currentTab?.code?.map((codeBlock) => {
            return (
              <CodeSyntaxHighlighted key={codeBlock._uid} {...codeBlock} />
            );
          })}
      </div>
    </Box>
  );
}
