/**
 * ------------------------------------------------------------------------------
 * Abstraction to handle finding and animating progress indicator
 * ------------------------------------------------------------------------------
 */
import { animate, scroll } from "motion";

import {
  ANIMATED,
  getSingleElemByMotionAttr,
} from "~utils/motion_one/motion_attribute_selectors";

import type { RefObject } from "react";

export const animateProgressIndicator = (ref?: RefObject<HTMLDivElement>) => {
  if (!ref?.current) return;

  const progressIndicatorElement = getSingleElemByMotionAttr(
    ref,
    ANIMATED.progress
  );

  if (ref.current && progressIndicatorElement) {
    scroll(
      animate(progressIndicatorElement, {
        strokeDasharray: ["0,1", "1,1"],
      }),
      {
        target: ref.current,
      }
    );
  }
};
