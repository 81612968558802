import React from "react";

import clsx from "clsx";
import { v4 as uuid } from "uuid";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";

import { checkHasLength } from "~utils/__deprecated__/checkHasLength";

import { Card } from "~components/Card";
import { Grid } from "~components/Grid";
import { StoryblokEditable } from "~components/StoryblokEditable";

import { gridContainerStyle } from "./styles.css";

import type { BoxProps } from "~components/Box";
import type { CardProps } from "~components/Card";
import type {
  VariantCardAppearanceEnum,
  VariantFeaturedContentTypeEnum,
} from "~components/Card/styles.css";
import type { VariantBackgroundColorEnum } from "~styles/common/variant.backgroundColor.css";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface GridCardsProps extends BoxProps, StoryblokBlok {
  appearance?: VariantCardAppearanceEnum;
  cards?: Array<StoryblokBlok & CardProps>;
  className?: string;
  subComponents?: CardProps["subComponents"];
  isTitlePrefixReversed?: boolean;
  backgroundColor?: VariantBackgroundColorEnum;
  featuredContentType?: VariantFeaturedContentTypeEnum;
  readMoreCta?: boolean;
  featuredCta?: Array<StoryblokBlok>;
  pageEntries?: unknown;
}

export function GridCards({
  appearance,
  className: userClassName,
  cards,
  rowGap = "gutterWidth",
  columnGap = "gutterWidth",
  alignItems = "stretch",
  subComponents,
  isTitlePrefixReversed,
  gridTemplateColumns,
  padding,
  backgroundColor,
  featuredCta,
  featuredContentType,
  readMoreCta: _readMoreCta,
  pageEntries: _pageEntries,
  ...rest
}: GridCardsProps) {
  const hasItems = cards && checkHasLength(cards);

  const gridCardClassName = clsx(
    userClassName,
    backgroundColor ? variantBackgroundColor[backgroundColor] : "",
    gridContainerStyle
  );

  return (
    <Grid
      data-testid="feature-grid"
      alignItems={alignItems}
      gridTemplateColumns={gridTemplateColumns}
      rowGap={rowGap}
      columnGap={columnGap}
      className={gridCardClassName}
      padding={padding}
      borderRadius="md"
      {...rest}
    >
      {hasItems &&
        cards?.map((item) => {
          return (
            <StoryblokEditable key={uuid()} {...item}>
              <Card
                minWidth="100%"
                subComponents={subComponents}
                {...item}
                appearance={appearance || item.appearance}
                title={isTitlePrefixReversed ? item.prefix : item.title}
                prefix={isTitlePrefixReversed ? item.title : item.prefix}
                featuredContentType={featuredContentType}
                featuredCta={featuredCta}
              />
            </StoryblokEditable>
          );
        })}
    </Grid>
  );
}
