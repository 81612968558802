import React from "react";

import { faFacebookF } from "@fortawesome/free-brands-svg-icons/faFacebookF";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons/faLinkedinIn";
import { faTwitter } from "@fortawesome/free-brands-svg-icons/faTwitter";
import { faYoutube } from "@fortawesome/free-brands-svg-icons/faYoutube";

import { Box } from "~components/Box";
import { ButtonLink } from "~components/ButtonLink";

import type { FooterProps } from ".";
import type { BoxProps } from "~components/Box";
import type { IconFontAwesomeProps } from "~components/IconFontAwesome";

interface FooterSocialLegalProps extends BoxProps {
  socialButtons?: FooterProps["socialButtons"];
  regulatoryDisclaimer: FooterProps["regulatoryDisclaimer"];
}

const SOCIAL_BUTTONS_MAP: Record<string, IconFontAwesomeProps["icon"]> = {
  linkedin: faLinkedinIn,
  youtube: faYoutube,
  facebook: faFacebookF,
  twitter: faTwitter,
} as const;

const GRID_TEMPLATE_COLUMNS = { mobile: 1, tablet: 2 } as const;

export function FooterSocialLegal({
  socialButtons,
  regulatoryDisclaimer,
  ...rest
}: FooterSocialLegalProps) {
  return (
    <Box
      display="grid"
      gap="gutterWidth"
      gridTemplateColumns={GRID_TEMPLATE_COLUMNS}
      alignItems="center"
      {...rest}
    >
      <Box
        color="text_lowContrast"
        textAppearance="body_sm"
        maxWidth="gridSpan6"
        width="100%"
      >
        {regulatoryDisclaimer}
      </Box>
      <Box display="flex" gap="spacing2" marginLeft="auto">
        {Array.isArray(socialButtons) &&
          socialButtons.map((socialButton, index) => {
            if (!socialButton.link || !socialButton.fontAwesomeIcon)
              return null;

            return (
              <ButtonLink
                shouldHaveArrowIcon={false}
                /** Data is static, can key by index */
                // eslint-disable-next-line react/no-array-index-key
                key={`${socialButton.fontAwesomeIcon}-${index}`}
                link={socialButton.link}
                iconLeft={SOCIAL_BUTTONS_MAP[socialButton.fontAwesomeIcon]}
                data-testid="social-button"
                size="md_square"
                color="ui_primary_base"
                appearance="secondary"
                marginY="none"
              />
            );
          })}
      </Box>
    </Box>
  );
}
