/**
 * ------------------------------------------------------------------------------
 * Abstraction for getting the translate Y values for an animated element
 * ------------------------------------------------------------------------------
 */
import { calc } from "@vanilla-extract/css-utils";

import { TRANSLATE_LG, TRANSLATE_SM } from "../config";

import type { AnimationOrientation } from "../types";

type Translation = `translate${"Y" | "X"}(${string})`;

interface GetDirectionAwareTranslateYArgs {
  orientation?: AnimationOrientation;
  scale?: "sm" | "md";
  isNegative?: boolean;
}

export const getTranslation = ({
  isNegative,
  orientation = "vertical",
  scale = "md",
}: GetDirectionAwareTranslateYArgs): Translation => {
  const translateOrientation =
    orientation === "vertical" ? "translateY" : "translateX";

  const translationAmount = scale === "md" ? TRANSLATE_LG : TRANSLATE_SM;

  if (isNegative) {
    return `${translateOrientation}(${calc.multiply(translationAmount, -1)})`;
  }

  return `${translateOrientation}(${translationAmount})`;
};
