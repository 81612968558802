import { useContext, useMemo } from "react";

import { filterBooleanTs } from "~utils/filterBooleanTs";
import { getIsInEditor } from "~utils/storyblok/getIsInEditor";

import { PageQueryContext } from "~context/PageQueryContext";

import type { Hit } from "instantsearch.js";
import type { ContentSearchHit } from "~sections/ContentSearchResults";
import type {
  SbRelationsGraphQlNode,
  StoryblokEntry,
  StoryblokRelationsNodeType,
} from "~types/storyblok.types";

export interface TransformedRelationObject {
  title?: string;
}

export interface UseStoryblokRelationsArgs<TOutputComponentProps> {
  content?: Array<string | StoryblokEntry>;
  transformApiEntry: (content: StoryblokEntry) => TOutputComponentProps;
  transformGraphQlNode: (
    content: SbRelationsGraphQlNode
  ) => TOutputComponentProps;
  isAlphabetized?: boolean;
  storyblokRelationsNodeType: StoryblokRelationsNodeType;
  hits?: Hit<ContentSearchHit>[];
}

function alphabetize<TOutputObjectShape>(
  objects: Array<TOutputObjectShape & TransformedRelationObject>
): Array<TOutputObjectShape> | null {
  if (!Array.isArray(objects)) return null;

  return objects.sort((a, b) => {
    if (a.title && b.title && a.title < b.title) {
      return -1;
    }
    if (a.title && b.title && a.title > b.title) {
      return 1;
    }

    return 0;
  });
}

function getNodesInScope({
  content,
  nodes,
  hits,
}: {
  content: Array<string | StoryblokEntry>;

  nodes: Array<SbRelationsGraphQlNode>;
  hits?: Hit<ContentSearchHit>[];
}) {
  const nodesInScope: SbRelationsGraphQlNode[] = [];
  content.forEach((contentID) => {
    const filteredNode = nodes.find((node) => {
      return node.uuid === contentID;
    });
    if (!filteredNode) return;

    const { topics: algoliaTopics } =
      hits?.find((hit) => {
        return hit.id === contentID;
      }) ?? {};

    nodesInScope.push({
      ...filteredNode,
      topics: algoliaTopics || filteredNode.topics,
    });
  });

  return nodesInScope;
}

/**
 * Handles parsing relations that may arrive in 2 different formats,
 * in production, and in the Storyblok visual editor
 */
export function useStoryblokRelations<
  TOutputComponentProps extends TransformedRelationObject
>({
  content,
  transformApiEntry,
  transformGraphQlNode,
  storyblokRelationsNodeType,
  isAlphabetized,
  hits,
}: UseStoryblokRelationsArgs<TOutputComponentProps>): Array<TOutputComponentProps> {
  /**
   * Get relations from parent page query response,
   * which is made available via context provider.
   */
  const { data } = useContext(PageQueryContext);

  const graphQlRelationsResponse = data
    ? data[storyblokRelationsNodeType]
    : null;

  const { nodes } = graphQlRelationsResponse || {};

  return (
    useMemo(() => {
      if (!Array.isArray(content)) return null;

      /**
       * ----------------------------------------------
       * In Storyblok visual editor, we can expect `content`
       * to be an array of objects of type `StoryblokEntry`
       * ----------------------------------------------
       */

      if (getIsInEditor()) {
        const transformedRelations = (content as Array<StoryblokEntry>)
          .map(transformApiEntry)
          .filter(filterBooleanTs);

        if (isAlphabetized && transformedRelations) {
          return alphabetize<TOutputComponentProps>(transformedRelations);
        }

        return transformedRelations;
      }

      /**
       * ----------------------------------------------
       * In production, we can expect an array of UUIDs,
       * where the content is queried in the parent page
       * GraphQL query and we have to resolve the data
       * using the UUIDs manually.
       * ----------------------------------------------
       */

      if (!Array.isArray(nodes)) return null;

      const nodesInScope = getNodesInScope({ content, nodes, hits });
      const transformedRelations = nodesInScope?.map(transformGraphQlNode);

      /**
       * ----------------------------------------------
       * Optionally sort output objects by title key
       * ----------------------------------------------
       */

      if (isAlphabetized && transformedRelations) {
        return alphabetize<TOutputComponentProps>(transformedRelations);
      }

      return transformedRelations;
    }, [
      content,
      transformApiEntry,
      transformGraphQlNode,
      isAlphabetized,
      nodes,
      hits,
    ]) ?? []
  );
}
