import type { UseMultipleSelectionStateChange } from "downshift";
import type { ClearRefinementsRenderState } from "instantsearch.js/es/connectors/clear-refinements/connectClearRefinements";
import type { RefinementListRenderState } from "instantsearch.js/es/connectors/refinement-list/connectRefinementList";
import type { DropdownItemShape } from "~types/global.types";

interface HandleAlgoliaRefinementSelectionArgs {
  changes: UseMultipleSelectionStateChange<DropdownItemShape>;
  refineSearchByAttribute: RefinementListRenderState["refine"];
  clearRefinements: ClearRefinementsRenderState["refine"];
}

export const handleAlgoliaRefinementSelection = ({
  changes,
  refineSearchByAttribute,
  clearRefinements,
}: HandleAlgoliaRefinementSelectionArgs) => {
  if (!refineSearchByAttribute) return null;
  const { selectedItems } = changes || {};

  clearRefinements();

  return selectedItems?.map((selectedItem) => {
    return refineSearchByAttribute(selectedItem.value);
  });
};
